<template>
    <div class="layout-login flex h-full flex-col justify-center bg-zinc-50">
        <LayoutContained>
            <div class="mx-auto w-full overflow-hidden rounded-md bg-white shadow-2xl sm:max-w-lg">
                <div class="bg-sky-600 p-5 text-xl font-bold text-white">
                    Login to {{ $config.public.companyName }}
                </div>
                <div>
                    <div class="px-5 pb-4 pt-6" v-show="!loading">
                        <slot></slot>
                        <AlertError class="mt-3" :alert="error"></AlertError>
                        <div class="flex flex-row" v-if="showRestartLogin">
                            <div class="flex grow"></div>
                            <ButtonRegular
                                class="mt-5"
                                buttonText="Try Again"
                                :buttonClick="restartLogin"
                            ></ButtonRegular>
                        </div>
                    </div>
                    <LoadingWidget v-show="loading"></LoadingWidget>
                </div>
            </div>
        </LayoutContained>
    </div>
</template>

<script>
import { mapGetters } from 'pinia';

export default {
    name: 'LayoutLogin',
    props: ['loading', 'error', 'restartLogin'],
    computed: {
        ...mapGetters(useStoreLogin, {
            showRestartLogin: 'showRestartLogin',
        }),
    },
};
</script>
