<template>
    <div>
        <div :ref="formRef">
            <div class="mb-8">
                <label for="email" class="text-md block font-semibold leading-6 text-gray-900">
                    Enter your email address
                </label>
                <div class="mt-3">
                    <input type="email" name="email" id="email" autocomplete="email" :value="email"
                           placeholder="Email address"
                           @input="event => updateForm({field: 'email', value: event.target.value})"
                           class="block w-full rounded-md border-0 px-4 py-5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                                placeholder:text-base placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600">
                </div>
            </div>
            <div class="mb-8">
                <div class="relative flex items-start">
                    <div class="flex h-6 items-center">
                        <input id="remember" name="remember" type="checkbox" v-model="remember"
                               class="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600">
                    </div>
                    <div class="ml-3 text-sm leading-6">
                        <label for="remember" class="font-medium text-gray-900">Remember me?</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-row">
            <ButtonAction
                class="-ml-1"
                buttonColor="bg-transparent hover:bg-zinc-100"
                buttonPadding="px-2 py-2"
                buttonText="Create account"
                buttonRoute="/signup"
            ></ButtonAction>
            <div class="grow"></div>
            <ButtonAction
                :form="formRef"
                buttonText="Next"
                :buttonClick="onClickNext"
            ></ButtonAction>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormLoginEmailChange',
    props: {
        initEmail: {
            type: String,
        },
        initRemember: {
            type: Boolean,
        },
        changeEmail: {
            type: Function,
        },
    },
    data () {
        return {
            email: null,
            remember: false,
            formRef: 'loginEmail',
            valid: false,
            emailRules: [
                v => !!v || 'Email is required'
            ],
        };
    },
    methods: {
        updateForm ({value}) {
            this.email = value;
        },
        onClickNext () {
            this.changeEmail({
                email: this.email,
                remember: this.remember,
            });
        },
    },
    mounted: function () {
        this.email = this.initEmail;
        this.remember = this.initRemember;
    },
};
</script>
